import React, { useState, useEffect } from "react";
import { Grid, Card, CardContent, Typography, Box, Select, TextField, CircularProgress,Autocomplete } from "@mui/material";
import { Icon } from "@iconify/react";
import useAuth from "src/hooks/useAuth";
import { axiosInstance as axios} from "src/utils/axios";

interface OrgCardProps {
    selectedOption: any;
    onSelectChange: (option: any) => void;
}

const OrgCard: React.FC<OrgCardProps> = ({ selectedOption, onSelectChange }) => {
    const { user } = useAuth();
    const [hotelOptions, setHotelOptions] = useState<any[]>([]);
    const [loading, setLoading] = useState(false);
    
    useEffect(() => {
        const fetchHotels = async () => {
            setLoading(true);
            try {
                let hotelsData = [];
                if (user?.role === 10) {
                    const response = await axios("uberSearch?on=hotels&searchTerm=");
                    hotelsData = await response.data;
                } else {
                    hotelsData = user?.hotels || [];
                }
                setHotelOptions(hotelsData);

                // Auto-select the default hotel if user.hotel_id exists
                const defaultHotel = hotelsData.find((hotel: any) => hotel.id === user?.hotel_id);
                if (defaultHotel) {
                    onSelectChange(defaultHotel);
                }
            } catch (error) {
                console.error("Error fetching hotels:", error);
            } finally {
                setLoading(false);
            }
        };

        fetchHotels();
    }, [user, onSelectChange]);

    // Set the default option on component mount or page revisit
    useEffect(() => {
    if (user?.hotel_id && !selectedOption) {
        // onSelectChange(user.hotels[0]);
        const matchedHotel = user.hotels.find((hotel:any) => hotel.id === user.hotel_id);    
        if (matchedHotel) {
        onSelectChange(matchedHotel);
        }
    }
    }, [user, selectedOption,onSelectChange]);
  
    // const handleSelectChange = (event: any) => {
    //     onSelectChange(event.target.value);
    // };

    return (
        <>
            {/* Container Box for Area Selector and Hotel Details */}
            <Box
                sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    padding: 4,
                    gap: 4,
                    flexWrap: "wrap",
                    maxWidth: "100%",
                    overflow: "hidden"
                }}
            >
                {/* Area Selector Section */}
                <Card
                    sx={{
                        padding: 4,
                        borderRadius: 2,
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                        minWidth: 350, // Adjust width for alignment
                        height: 200, // Set height to align with hotel details card
                    }}
                >
                    <Typography variant="h5" sx={{ color: "#5a6e8c", marginBottom: 2 }}>
                        SELECT AREA
                    </Typography>
                    {loading ? (
                        <CircularProgress />
                    ) : (
                        // <Select value={selectedOption || ""} onChange={handleSelectChange} sx={{ width: "300px", borderRadius: "8px", border: "1px solid #1a237e", height: "40px" }}>
                        //     {hotelOptions.map((org: any) => (
                        //         <MenuItem key={org.id} value={org}>{org.name}</MenuItem>
                        //     ))}
                        // </Select>
                        <Autocomplete
                        options={hotelOptions} // Ensure it's an array of objects
                        getOptionLabel={(option) => option?.name || ""} // Extract correct label
                        value={hotelOptions.find((option) => option.id === selectedOption?.id) || null} // Ensure correct selection
                        onChange={(_event, newValue) => onSelectChange(newValue)} // Pass selected object directly
                        isOptionEqualToValue={(option, value) => option.id === value?.id} // Ensure correct object comparison
                        renderInput={(params) => (
                            <TextField 
                            {...params} 
                            label="Select Hotel" 
                            variant="outlined" 
                            sx={{ width: "300px", borderRadius: "8px" }} 
                            />
                        )}
                        sx={{ width: "300px" }}
                        />


                    )}
                </Card>

                {/* Hotel Details Card */}
                {selectedOption && (
                    <Card
                        sx={{
                            padding: 4,
                            borderRadius: 2,
                            minWidth: 400,
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            alignItems: "center",
                        }}
                    >
                        <Box sx={{ display: "flex", alignItems: "center", marginBottom: 2 }}>
                            {/* Logo Section */}
                            <Box
                                sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    borderRight: "1px solid #ddd",
                                    paddingRight: 2,
                                    marginRight: 2,
                                    maxWidth: 365, // Adjust width to avoid clipping
                                    height: 80, // Fixed height for consistency
                                }}
                            >
                                <img
                                    src={selectedOption?.logo_img}
                                    alt={`${selectedOption.name} logo`}
                                    style={{ maxWidth: "100%", maxHeight: "100%", objectFit: "contain" }}
                                />
                            </Box>

                            {/* Hotel Info Section */}
                            <Box sx={{ paddingLeft: 2, flex: 1 }}>
                                <Typography variant="h4" sx={{ fontWeight: "bold" }}>
                                    {selectedOption.name}
                                </Typography>
                                <Typography variant="body2">
                                    ID: {selectedOption.id} • {selectedOption.city}
                                </Typography>
                                <Typography variant="body2">UID: {selectedOption.uid}</Typography>
                                <Box sx={{ marginTop: 1 }}>
                                    <span
                                        style={{
                                            color: "white",
                                            fontSize: "14px",
                                            padding: "4px 8px",
                                            borderRadius: "4px",
                                            backgroundColor:
                                                selectedOption.status > 0
                                                    ? "green"
                                                    : selectedOption.status === -1 || selectedOption.status === 0
                                                        ? "red"
                                                        : "orange",
                                        }}
                                    >
                                        {selectedOption.status > 0
                                            ? "Enabled"
                                            : selectedOption.status === -1
                                                ? "Disabled for non-payment"
                                                : selectedOption.status === 0
                                                    ? "Disabled"
                                                    : "Warning for non-payment"}
                                    </span>
                                </Box>
                            </Box>
                        </Box>

                        {/* Address Section */}
                        <Box sx={{ borderTop: "1px solid #ddd", paddingTop: 2 }}>
                            <Typography variant="body2">
                                {selectedOption.address1} {selectedOption.address2}, {selectedOption.city}
                            </Typography>
                        </Box>
                    </Card>
                )}
            </Box>
        </>
    )
}

export default OrgCard