
import React, { createContext, useContext, useState } from "react";

interface FilterState {
    start_date: string | null | undefined;
    end_date: string | null | undefined;
    created_by_dept: string | null | undefined;
    trig: string | null | undefined;
    assign_to: string | null | undefined;
    gc: string | null | undefined;
    priority: string | null | undefined;
    esc_status: string | null | undefined;
    user_id: undefined;
    item_ids: undefined;
    dept_ids: undefined;
    request_statuses: undefined;
    [key: string]: string | null | undefined; // Update index signature
  }

interface FilterContextType {
  snagFilters: FilterState;
  setSnagFilters: (filters: Partial<FilterState>) => void;
  resetFilters: () => void;
}

const initialFilters: FilterState = {
  start_date: null,
  end_date: null,
  created_by_dept: null,
  trig: null,
  assign_to: null,
  gc: null,
  priority: null,
  esc_status: null,
  user_id: undefined,
  item_ids: undefined,
  dept_ids: undefined,
  request_statuses: undefined
};

const FilterContext = createContext<FilterContextType | undefined>(undefined);

export const FilterProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [snagFilters, setSnagFilters] = useState<FilterState>(initialFilters);

  const updateFilters = (newFilters: Partial<FilterState>) => {
    setSnagFilters(prevFilters => ({
      ...prevFilters,
      ...newFilters
    }));
  };

  const resetFilters = () => {
    setSnagFilters(initialFilters);
  };

  return (
    <FilterContext.Provider value={{ snagFilters, setSnagFilters: updateFilters, resetFilters }}>
      {children}
    </FilterContext.Provider>
  );
};

export const useSnagFilters = () => {
  const context = useContext(FilterContext);
  if (!context) {
    throw new Error("useSnagFilters must be used within a FilterProvider");
  }
  return context;
};