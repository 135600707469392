import { Link as RouterLink } from 'react-router-dom';
// @mui
import { styled } from '@mui/material/styles';
import { Typography, Button, Card, CardContent, Box, CardProps } from '@mui/material';
import { useTranslation } from 'react-i18next';
import useAuth from '../../../../hooks/useAuth';
import Image from '../../../../components/Image';

// ----------------------------------------------------------------------

const RootStyle = styled(Card)(({ theme }) => ({
  boxShadow: 'none',
  textAlign: 'center',
  backgroundColor: theme.palette.grey[500_24],
  [theme.breakpoints.up('md')]: {
    height: '100%',
    display: 'flex',
    textAlign: 'left',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
}));

// ----------------------------------------------------------------------

export default function DashWelcome({ displayName, org }: any) {
  const { user } = useAuth();
  const { t } = useTranslation();

  // Filter hotels in user object to find the ones matching org IDs from props
  const selectedOrgs = user?.hotels?.filter((hotel: any) => org.includes(hotel.id)) || [];

  return (
    <RootStyle>
      <CardContent
        sx={{
          p: { md: 5 },
          pl: { md: 2 },
          color: 'white.800',
          order: 2,
        }}
      >
        <Typography gutterBottom variant="h4">
          {t('Welcome back')}, {!displayName ? '...' : displayName}!
        </Typography>

        {org.length === 1 ? (
          <Typography variant="body2" sx={{ maxWidth: 540, mx: 'auto' }}>
            Here's a summary of what's happening across your Org. ({selectedOrgs[0]?.name},ID:{selectedOrgs[0]?.id})
          </Typography>
        ) : (
          <Typography variant="body2" sx={{ maxWidth: 540, mx: 'auto' }}>
            Here's a summary of what's happening across your Orgs. (
              {selectedOrgs
              .map((hotel: any) => `(${hotel.name}, ID:${hotel.id})`)
              .join(', ')}
            )
          </Typography>
        )}
      </CardContent>

      {/* <Box sx={{ order: 0 }} style={{ position: 'relative' }}>
        {org.length === 1 ? (
          <Image
            src={selectedOrgs[0]?.logo_img ?? ''}
            alt={`${selectedOrgs[0]?.name} Logo`}
            sx={{
              p: 1,
              height: 200,
              margin: { xs: 'auto', md: 'inherit' },
              objectFit: 'contain',
            }}
          />
        ) : (
          <Image
            src={'/assets/illustrations/bloom-checking-mails.png'} // Empty string if multiple orgs, no image
            sx={{
              p: 1,
              height: 200,
              margin: { xs: 'auto', md: 'inherit' },
              objectFit: 'contain',
            }}
          />
        )}
      </Box> */}
    <Box
      sx={{
        order: 0,
        position: 'relative',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: 200, 
        width: '100%', 
        overflow: 'hidden', // Prevents content overflow
      }}
    >
      <Box
        component="img"
        src={org.length === 1 ? selectedOrgs[0]?.logo_img ?? '' : '/assets/illustrations/bloom-checking-mails.png'}
        alt={org.length === 1 ? `${selectedOrgs[0]?.name} Logo` : 'Default illustration'}
        sx={{
          width: '100%', // Ensures it stretches for small logos
          height: '100%', // Ensures it stretches for small logos
          maxWidth: 'auto',
          maxHeight: '100%',
          objectFit: 'contain', // Fits large logos
          padding: 'center'
        }}
      />
    </Box>
    </RootStyle>
  );
}
