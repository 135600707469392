import React from 'react';
// @mui
import {Box, Select, TextField, FormControl,Autocomplete } from '@mui/material';
// utils
import useAuth from "../../../hooks/useAuth";
import {axiosInstance as axios} from "../../../utils/axios";

// ----------------------------------------------------------------------
type Props = {
    isCollapsed: boolean | undefined;
};

export default function AtomOrgSwitcher({ isCollapsed }: Props) {

  const { user } = useAuth();

  const handleChange = (event: any, newValue: any) => {
    if (!newValue) return; // Ensure a valid selection

    const data = {
        hotel_id: newValue.id, // Get the selected hotel's ID
    };

    axios.post('/user/org-associations-set', data)
        .then(() => {
            window.location.reload();
        })
        .catch((error) => {
            console.error("Error updating hotel:", error);
        });
};


  return (
    <Box sx={{opacity: isCollapsed?0:1}}>
        <FormControl fullWidth>
              <Autocomplete
                  options={user?.hotels || []}
                  getOptionLabel={(option: any) => option.name}
                  value={user?.hotel || null}
                  onChange={handleChange}
                  fullWidth
                  size={'small'}
                  renderInput={(params) => (
                      <TextField
                          {...params}
                          // label="Org. Switcher"
                          variant="outlined"
                          sx={{
                            border: '2px solid #2196f3', // Persistent blue border
                            borderRadius: 1,
                            height: '40px', // Ensures consistent height
                            boxShadow: '0 0 5px #2196f3', // Glow effect for the blue highlight
                            '&:hover': {
                              borderColor: '#42a5f5', // Slightly lighter blue on hover
                            },
                            '&:focus': {
                              borderColor: '#1e88e5', // Darker blue on focus
                            },
                          }}
                      />
                  )}
              />
        </FormControl>
    </Box>
  );
}
